import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input } from 'react-ui'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Error from './Error'
import { Content } from './Login'
import SecondaryButtons from './SecondaryButtons'

import { actions as authActions } from './store'
import Validator from './Validator'

const Text = styled.div`
  color: #555;
  font-size: 14px;
  padding: 16px;
  margin-bottom: 16px;
  background: #f7f8f2;
  margin-top: -8px;
`

class ResetPassword extends Component {
  state = {
    showResetPasswordForm: false,
    email: '',
    sendPasswordTokenErr: ''
  }
  errText = err => {
    switch (err) {
      case 'INVALID_PASSWORD_TOKEN':
        return 'Ungültiger Passwort-Token'
      default:
        return err
    }
  }
  sendPasswordToken = e => {
    e.preventDefault()
    const { actions } = this.props
    let { email } = e.target.elements
    email = email.value.trim()
    this.setState(() => ({
      sendPasswordTokenErr: ''
    }), () => {
      const emailErr = Validator.validateEmail(email)
      if (emailErr) {
        this.setState(() => ({
          sendPasswordTokenErr: emailErr
        }))
        return
      }
      actions.auth.sendPasswordToken(email)
      this.setState(() => ({
        showResetPasswordForm: true,
        email
      }))
    })
  }
  changePassword = e => {
    e.preventDefault()
    const { actions } = this.props
    const { email } = this.state
    let { passwordToken, newPassword } = e.target.elements
    passwordToken = passwordToken.value.trim()
    newPassword = newPassword.value.trim()
    this.setState(() => ({
      changePasswordErr: ''
    }), async () => {
      const passwordTokenErr = Validator.validatePasswordToken(passwordToken)
      if (passwordTokenErr) {
        this.setState(() => ({
          changePasswordErr: passwordTokenErr
        }))
        return
      }
      const passwordErr = Validator.validatePassword(newPassword)
      if (passwordErr) {
        this.setState(() => ({
          changePasswordErr: passwordErr
        }))
        return
      }
      const res = await actions.auth.resetPassword(email, passwordToken, newPassword)
      if (res && res.err) {
        this.setState(() => ({
          changePasswordErr: res.err
        }))
      } else if (res && res.success) {
        await actions.auth.login(email, newPassword)
      }
    })
  }

  renderSendPasswordTokenForm() {
    const { sendPasswordTokenErr } = this.state
    return (
      <div>
        <Text>
          Geben Sie hier Ihre E-Mail-Adresse ein mit der Sie sich beim Lernzentrum registriert
          haben.
        </Text>
        <form onSubmit={this.sendPasswordToken}>
          <Input
            label="E-Mail"
            name="email"
            autoFocus
          />
          {sendPasswordTokenErr && (
            <Error>{sendPasswordTokenErr}</Error>
          )}
          <Button>Passwort-Token zuschicken</Button>
        </form>
      </div>
    )
  }

  renderResetPasswordForm() {
    const { changePasswordErr } = this.state
    return (
      <div>
        <form onSubmit={this.changePassword}>
          <Input
            label="Passwort-Token"
            name="passwordToken"
            autoFocus
          />
          <Input
            type="password"
            label="Neues Passwort"
            name="newPassword"
            placeholder="Min. 8 Zeichen"
          />
          {changePasswordErr && (
            <Error>{this.errText(changePasswordErr)}</Error>
          )}
          <Button>Passwort ändern</Button>
        </form>
      </div>
    )
  }

  render() {
    const { className, onShowLogin } = this.props
    const { showResetPasswordForm } = this.state
    return (
      <div className={className}>
        <Content>
          <h1>Passwort ändern</h1>
          {showResetPasswordForm ?
            this.renderResetPasswordForm() :
            this.renderSendPasswordTokenForm()
          }
          <SecondaryButtons>
            <Button
              type="button"
              secondary="true"
              onClick={onShowLogin}
            >
              Zurück zum Login
            </Button>
          </SecondaryButtons>
        </Content>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(ResetPassword)`
  width: 480px;
`)
