import React, {useState} from 'react'
import styled, {css} from 'styled-components'

const LinkButton = styled.button`
  border: 0;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;
  font-weight: 300;
  
  ${p => p.selected && css`
    font-weight: 400;
  `};
`

const SortBy = ({className, onSortBy}) => {
  const [sortBy, setSortBy] = useState('title')

  const handleSortBy = sortBy => () => {
    setSortBy(sortBy)
    onSortBy(sortBy)
  }

  return (
    <div className={className}>
      Sortieren nach:
      <LinkButton
        onClick={handleSortBy('title')}
        selected={sortBy === 'title'}
      >
        Titel
      </LinkButton>
      <LinkButton
        onClick={handleSortBy('createdAt')}
        selected={sortBy === 'createdAt'}
      >
        Zuletzt hinzugefügt
      </LinkButton>
    </div>
  )
}

export default styled(SortBy)`
  margin-bottom: 16px;
`