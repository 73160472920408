import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import MediaList from '../../components/MediaList'
import { actions as appActions } from '../../store/app'
import Details from '../Details';

class Search extends Component {
  state = {}

  componentDidMount() {
    const { match } = this.props
    const query = this.decodeQuery(match.params.searchQuery)
    this.doSearch(query)
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.match.params.searchQuery !== this.props.match.params.searchQuery) {
    //   const query = this.decodeQuery(this.props.match.params.searchQuery)
    //   this.doSearch(query)
    // }
  }

  decodeQuery = query => {
    return atob(decodeURIComponent(query))
  }

  doSearch = query => {
    const { actions } = this.props
    actions.app.setSearchQuery(query)
    actions.app.search(query)
  }

  showMoreSearchResults = () => {
    const { actions } = this.props
    actions.app.loadMoreSearchResults()
  }

  render() {
    const {
      className,
      searchQuery,
      searchMedia,
      hasMoreMedia,
      match,
      loading
    } = this.props
    return (
      <div className={className}>
        <h1>Suchergebnisse für <strong>{searchQuery}</strong></h1>
        <MediaList
          loading={loading}
          items={searchMedia}
          match={match}
          showLoadMore={hasMoreMedia}
          onLoadMore={this.showMoreSearchResults}
        />
        <Route
          path={`${match.path}/media/media-id/:mediaID`}
          component={Details}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchQuery: state.app.searchQuery,
  searchMedia: state.app.searchMedia,
  hasMoreMedia: state.app.hasMoreMedia,
  loading: state.app.loading
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Search)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffffee;
  overflow: hidden;
  overflow-y: auto;
  padding: 32px;

  h1 {
    margin: 0;
    font-weight: 300;
    font-size: 20px;
  }

  ${MediaList} {
    margin-top: 16px;
  }
`)
