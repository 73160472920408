import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as authActions } from './Auth/store'
import Header from './Header'
import MainView from './MainView'
import Auth from './Auth'
import Privacy from './Privacy'
import Contact from './Contact'
import Footer from './Footer'
import lernzentrum from '../assets/lernzentrum.jpg'

const Content = styled.div`
  background-image: url(${lernzentrum});
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
`

class Views extends Component {
  componentDidMount() {
    const { actions } = this.props
    actions.auth.validateSession()
  }

  render() {
    const { className, authenticated, sessionChecked } = this.props
    if (!sessionChecked) {
      return null
    }
    return (
      <div className={className}>
        <Header />
        <Content className='main-content'>
          <Switch>
            <Route
              path='/privacy'
              component={Privacy}
            />
            <Route
              path='/contact'
              component={Contact}
            />
            <Route
              render={() => (
                authenticated ? (
                  <MainView />
                ) : (
                  <Auth />
                )
              )}
            />
          </Switch>
          <Footer />
        </Content>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  sessionChecked: state.auth.sessionChecked
})

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Views)`
  height: 100vh;
  display: flex;
  flex-direction: column;
`)
