import { Component } from 'react'

class ScrollMainContentToTopOnMount extends Component {
  componentDidMount() {
    document.querySelector('.main-content').scrollTop = 0
  }

  render() {
    return null
  }
}

export default ScrollMainContentToTopOnMount
