import PaginationService from '../services/pagination'

const initState = {
  loading: false,
  specialFields: [],
  categories: {},
  selectedCategory: null,
  categoryMedia: [],
  media: {},
  searchQuery: '',
  searchMedia: [],
  page: 1,
  sortBy: 'title',
  hasMoreMedia: false
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const groupCategoriesByID = (categories, specialFieldID) => {
  const group = {}
  for (const category of categories) {
    group[category.id] = {
      ...category,
      specialFieldID
    }
  }
  return group
}

const getRootCategory = categories => {
  return categories.find(category => !category.parent)
}

const categoryByID = (categoryId, categories) => {
  return categories[categoryId]
}

const getSpecialFields = () => async (dispatch, getState, send) => {
  let msg = await send({
    type: 'GET_SPECIAL_FIELDS'
  })

  const specialFields = msg?.payload?.specialFields
  if (!specialFields) {
    return
  }

  msg = await send({
    type: 'GET_SPECIAL_FIELDS_CATEGORIES'
  })

  const specialFieldsCategories = msg?.payload?.specialFieldsCategories
  if (!specialFieldsCategories) {
    return
  }

  let groupedSpecialFieldsCategories = {}

  for (let specialField of specialFields) {
    const categories = specialFieldsCategories[specialField.id]
    const rootCategory = getRootCategory(categories)
    rootCategory.name = specialField.name
    specialField.rootCategory = rootCategory
    groupedSpecialFieldsCategories = {
      ...groupedSpecialFieldsCategories,
      ...groupCategoriesByID(categories, specialField.id)
    }
  }

  dispatch({
    type: 'GET_SPECIAL_FIELDS',
    payload: {
      specialFields: specialFields,
      categories: groupedSpecialFieldsCategories
    }
  })
}

const selectCategory = (categoryId, sortBy) => async (dispatch, getState) => {
  const { categories } = getState().app
  const category = categoryByID(categoryId, categories)
  dispatch({
    type: 'LOAD_CATEGORY_MEDIA_BY_PAGE',
    payload: {
      category
    }
  })
  dispatch({
    type: 'SET_LOADING',
    payload: {
      loading: true
    }
  })
  await wait(50)
  dispatch(loadCategoryMediaByPage(category, 1, sortBy, []))
}

const loadMoreMedia = () => async (dispatch, getState) => {
  const { selectedCategory, page, sortBy, categoryMedia } = getState().app
  dispatch(loadCategoryMediaByPage(selectedCategory, page + 1, sortBy, categoryMedia))
}

const loadCategoryMediaByPage = (category, page, sortBy, categoryMedia) => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'CATEGORY_MEDIA',
    payload: {
      categoryId: category.id,
      page,
      sortBy
    }
  })
  if (!msg || !msg.payload || !msg.payload.media) {
    return
  }
  dispatch({
    type: 'LOAD_CATEGORY_MEDIA_BY_PAGE',
    payload: {
      loading: false,
      category,
      page,
      categoryMedia: [
        ...categoryMedia,
        ...msg.payload.media.slice(0, PaginationService.LIMIT)
      ],
      hasMoreMedia: msg.payload.media.length > PaginationService.LIMIT
    }
  })
}

const mediaByID = mediaID => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'MEDIA_BY_ID',
    payload: {
      mediaID
    }
  })
  if (!msg || !msg.payload || !msg.payload.media) {
    return
  }
  dispatch({
    type: 'MEDIA_BY_ID',
    payload: {
      media: msg.payload.media
    }
  })
}

const resetMedia = () => dispatch => {
  dispatch({
    type: 'RESET_MEDIA'
  })
}

const search = query => async (dispatch, getState, send) => {
  dispatch({
    type: 'SET_LOADING',
    payload: {
      loading: true
    }
  })
  await wait(50)
  const msg = await send({
    type: 'MEDIA_SEARCH',
    payload: {
      page: 1,
      query
    }
  })
  if (!msg || !msg.payload || !msg.payload.media) {
    return
  }
  dispatch({
    type: 'MEDIA_SEARCH',
    payload: {
      page: 1,
      searchQuery: query,
      searchMedia: msg.payload.media.slice(0, PaginationService.LIMIT),
      hasMoreMedia: msg.payload.media.length > PaginationService.LIMIT,
      loading: false
    }
  })
}

const loadMoreSearchResults = () => async (dispatch, getState, send) => {
  let { page, searchMedia, searchQuery } = getState().app
  page++
  const msg = await send({
    type: 'MEDIA_SEARCH',
    payload: {
      page,
      query: searchQuery
    }
  })
  if (!msg || !msg.payload || !msg.payload.media) {
    return
  }
  dispatch({
    type: 'MEDIA_SEARCH',
    payload: {
      page,
      searchQuery,
      searchMedia: [
        ...searchMedia,
        ...msg.payload.media.slice(0, PaginationService.LIMIT)
      ],
      hasMoreMedia: msg.payload.media.length > PaginationService.LIMIT
    }
  })
}

const setSearchQuery = query => dispatch => {
  dispatch({
    type: 'SET_SEARCH_QUERY',
    payload: {
      searchQuery: query
    }
  })
}

const resetSearchMedia = () => dispatch => {
  dispatch({
    type: 'RESET_SEARCH_MEDIA'
  })
}

const addTeachingMaterials = (mediaID, teachingMaterials) => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'MEDIA_ADD_TEACHING_MATERIALS',
    payload: {
      mediaID,
      teachingMaterials
    }
  })
  return msg && msg.payload && msg.payload.success
}

export const actions = {
  getSpecialFields,
  selectCategory,
  loadMoreMedia,
  mediaByID,
  resetMedia,
  search,
  loadMoreSearchResults,
  setSearchQuery,
  resetSearchMedia,
  addTeachingMaterials
}

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload.loading
      }
    case 'GET_SPECIAL_FIELDS':
      return {
        ...state,
        specialFields: action.payload.specialFields,
        categories: action.payload.categories
      }
    case 'LOAD_CATEGORY_MEDIA_BY_PAGE':
      return {
        ...state,
        selectedCategory: action.payload.category,
        categoryMedia: action.payload.categoryMedia,
        page: action.payload.page,
        hasMoreMedia: action.payload.hasMoreMedia,
        loading: action.payload.loading
      }
    case 'MEDIA_BY_ID':
      return {
        ...state,
        media: action.payload.media
      }
    case 'RESET_MEDIA':
      return {
        ...state,
        media: {}
      }
    case 'MEDIA_SEARCH':
      return {
        ...state,
        page: action.payload.page,
        searchMedia: action.payload.searchMedia,
        searchQuery: action.payload.searchQuery,
        hasMoreMedia: action.payload.hasMoreMedia,
        loading: action.payload.loading
      }
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        searchQuery: action.payload.searchQuery
      }
    case 'RESET_SEARCH_MEDIA':
      return {
        ...state,
        searchMedia: []
      }
    default:
      return state
  }
}