import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import CategoryPath from '../../components/CategoryPath'
import CategoriesService from '../../services/categories'

import { actions as appActions } from '../../store/app'
import CategoryMedia from './CategoryMedia'

const populateChildren = (children = [], categories) => {
  return children.map(categoryId => categories[categoryId])
}

const Inline = styled.div`
  display: flex;
`

const Note = styled.div`
  color: #888;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  margin: 8px 0;
`

const ChildCategories = styled.div`
  width: 240px;
  margin-right: 32px;
  
  h1 {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 18px;
  }

  a {
    color: ${p => p.theme.colors.main};
    font-weight: 300;
    display: flex;
    align-items: center;
    line-height: 16px;
    padding: 8px 0;
    padding-right: 32px;
    transition: ease-out 350ms;

    &:hover {
      color: #333;
    }
  }
`

const Content = styled.div`
  background: #fff;
  padding: 0 8px;
  border: 1px solid #dfdfdf;
`

class Categories extends Component {
  state = {}

  componentDidMount() {
    this.selectCategory(this.props.match.params.categoryId)
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    const { categoryId } = match.params
    if (prevProps.match.params.categoryId !== categoryId) {
      this.selectCategory(categoryId)
    }
  }

  selectCategory = (categoryId, sortBy) => {
    const { actions } = this.props
    actions.app.selectCategory(categoryId, sortBy)
  }

  handleCategorySelect = category => {
    const { history } = this.props
    history.push(`/special-fields/${category.specialFieldID}/category-id/${category.id}`)
  }

  onLoadMore = () => {
    const { actions } = this.props
    actions.app.loadMoreMedia()
  }

  onSortBy = sortBy => {
    this.selectCategory(this.props.match.params.categoryId, sortBy)
  }

  renderChildren = () => {
    const { selectedCategory, categories, match } = this.props
    const children = populateChildren(selectedCategory.children, categories)
    return (
      <ChildCategories>
        <Content>
          {!children.length && (
            <Note>Keine weiteren Unterkategorien vorhanden.</Note>
          )}
          <ul>
            {children.map(category => (
              <li
                key={category.id}
              >
                <NavLink
                  to={`/special-fields/${match.params.specialField}/category-id/${category.id}`}
                >
                  {category.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </Content>
      </ChildCategories>
    )
  }

  render() {
    const {
      className,
      selectedCategory,
      categoryMedia,
      match,
      specialFieldCategories,
      hasMoreMedia,
      loading
    } = this.props
    let categoryPath
    if (selectedCategory) {
      const category = specialFieldCategories[match.params.categoryId]
      categoryPath = CategoriesService.parentCategories(category, specialFieldCategories)
    }
    return (
      <div className={className}>
        {selectedCategory && (
          <CategoryPath
            categories={categoryPath}
            disableLastLink="true"
            onSelect={this.handleCategorySelect}
          />
        )}
        <Inline>
          {selectedCategory && this.renderChildren()}
          <CategoryMedia
            loading={loading}
            items={categoryMedia}
            match={match}
            showLoadMore={hasMoreMedia}
            onLoadMore={this.onLoadMore}
            onSortBy={this.onSortBy}
          />
        </Inline>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedCategory: state.app.selectedCategory,
  categories: state.app.categories,
  categoryMedia: state.app.categoryMedia,
  specialFieldCategories: state.app.categories,
  hasMoreMedia: state.app.hasMoreMedia,
  loading: state.app.loading
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Categories)`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  ${CategoryPath} {
    margin-bottom: 8px;
  }

  ${CategoryMedia} {
    flex: 1;
  }
`)