const validEmail = email => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email)
}

const validateEmail = email => {
  if (email === "") {
    return 'E-Mail-Adresse erforderlich'
  }
  if (!validEmail(email)) {
    return 'Ungültige E-Mail Adresse'
  }
  return ""
}

const validatePassword = password => {
  const PASSWORD_MIN_LENGTH = 8
  if (password.length === 0) {
    return 'Passwort erforderlich'
  }
  if (password.length < PASSWORD_MIN_LENGTH) {
    return 'Passwort ist zu kurz'
  }
  return ""
}

const validateToken = (passwordToken, name) => {
  const PASSWORD_TOKEN_LENGTH = 9
  if (passwordToken.length === 0) {
    return `${name} erforderlich`
  }
  if (passwordToken.length < PASSWORD_TOKEN_LENGTH) {
    return `${name} ist zu kurz`
  }
  if (passwordToken.length > PASSWORD_TOKEN_LENGTH) {
    return `${name} ist zu lang`
  }
  return ""
}

const validatePasswordToken = token => {
  return validateToken(token, 'Passwort-Token')
}

const validateAccountData = accountData => {
  const emailErr = validateEmail(accountData.email)
  if (emailErr) {
    return emailErr
  }
  const tokenErr = validateToken(accountData.accountActivationToken, 'Account-Aktivierungs-Token')
  if (tokenErr) {
    return tokenErr
  }
  if (accountData.firstName === "") {
    return 'Vorname erforderlich'
  }
  if (accountData.lastName === "") {
    return 'Nachname erforderlich'
  }
  const passwordErr = validatePassword(accountData.password)
  if (passwordErr) {
    return passwordErr
  }
  return ""
}

export default {
  validateEmail,
  validatePassword,
  validatePasswordToken,
  validateAccountData
}