const theme = {
  colors: {
    // main: '#43A047',
    main: '#66bb6a',
    text: '#333',
    err: '#e53935',
    success: '#43a047',
    background: '#ffffffee'
  },
  vars: {
    headerHeight: '64px'
  },
  transition: 'transition: ease-out 200ms;'
}

export default theme
