let categoriesByID
let rootCategory

const groupCategoriesByID = categories => {
  const group = {}
  for (const category of categories) {
    group[category.id] = category
  }
  return group
}

const init = categories => {
  categoriesByID = groupCategoriesByID(categories)
  rootCategory = getRootCategory(categories)
}

const getRootCategory = categories => {
  return categories.find(category => !category.parent)
}

const categoryByID = categoryId => {
  return categoriesByID[categoryId]
}

const populateCategories = categoriesIDs => {
  return categoriesIDs.map(categoryByID)
}

const parentCategories = (category, categoriesByID) => {
  const parents = []
  while (category) {
    parents.unshift(category)
    category = categoriesByID[category.parent]
  }
  return parents
}

export default {
  categoryByID,
  populateCategories,
  parentCategories
}