import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CategoryPath from '../../components/CategoryPath'

const CategoryPathItem = styled.li`
  display: flex;
  align-items: center;

  ${CategoryPath} {
    flex: 1;

    ul {
      min-height: 24px;
    }
  }
`

const parentCategories = (category, categoriesByID) => {
  const parents = []
  while (category) {
    parents.unshift(category)
    category = categoriesByID[category.parent]
  }
  return parents
}

const getSuperPath = (pathToCheck, otherPath) => {
  let shortPath
  let longPath
  if (pathToCheck.length > otherPath.length) {
    longPath = pathToCheck
    shortPath = otherPath
  } else {
    longPath = otherPath
    shortPath = pathToCheck
  }
  for (let i=0; i < shortPath.length; i++) {
    if (pathToCheck[i].id !== otherPath[i].id) {
      return []
    }
  }
  return longPath
}

const addCategoryToCategoriesPath = (newCategoryPath, categoriesPaths) => {
  let updated
  for (const [idx, categoryPath] of categoriesPaths.entries()) {
    const superPath = getSuperPath(newCategoryPath, categoryPath)
    if (superPath.length) {
      categoriesPaths[idx] = superPath
      updated = true
      break
    }
  }
  if (!updated) {
    categoriesPaths.push(newCategoryPath)
  }
  categoriesPaths.sort((pathA, pathB) => {
    const pathAName = pathA.map(category => category.name).join(' ')
    const pathBName = pathB.map(category => category.name).join(' ')
    return pathAName.localeCompare(pathBName)
  })
}

const createCategoriesPaths = (items, specialFieldsCategories) => {
  if (!Object.keys(specialFieldsCategories).length) {
    return []
  }
  items = items.map(categoryId => specialFieldsCategories[categoryId])
  const categoriesPaths = items.map(category => {
    return parentCategories(
      category,
      specialFieldsCategories
    )
  })
  const superCategoriesPaths = []
  for (const categoryPath of categoriesPaths) {
    addCategoryToCategoriesPath(categoryPath, superCategoriesPaths)
  }
  return superCategoriesPaths
}

const CategoriesPaths = ({ className, categories, specialFieldsCategories, onSelect }) => {
  if (!categories || !categories.length || !Object.keys(specialFieldsCategories).length) {
    return null
  }
  const categoriesPaths = createCategoriesPaths(categories, specialFieldsCategories)
  return (
    <ul className={className}>
      {categoriesPaths.map((categoryPath, idx) => (
        <CategoryPathItem key={categoryPath[categoryPath.length-1].id}>
          <CategoryPath
            categories={categoryPath}
            highlight='highlight'
            onSelect={onSelect}
          />
        </CategoryPathItem>
      ))}
    </ul>
  )
}

const mapStateToProps = state => ({
  specialFieldsCategories: state.app.categories
})

export default connect(
  mapStateToProps
)(styled(CategoriesPaths)``)
