const initState = {
  sessionChecked: false,
  authenticated: false,
  allowedActions: []
}

const validateSession = () => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'VALIDATE_SESSION'
  })
  if (msg && msg.payload && msg.payload.valid) {
    dispatch({
      type: 'AUTHENTICATE',
      payload: {
        authenticated: true,
        allowedActions: msg.payload.allowedActions || []
      }
    })
  }
  dispatch({
    type: 'SESSION_CHECKED',
    payload: {
      sessionChecked: true
    }
  })
}

const login = (email, password) => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'LOGIN',
    payload: {
      email,
      password
    }
  })
  if (!msg || !msg.payload) {
    return {
      err: 'INVALID_CREDENTIALS'
    }
  }
  if (msg.payload.err) {
    return {
      err: msg.payload.err
    }
  }
  dispatch({
    type: 'AUTHENTICATE',
    payload: {
      authenticated: true,
      allowedActions: msg.payload.allowedActions || []
    }
  })
  return {
    success: true
  }
}

const logout = () => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'LOGOUT'
  })
  if (!msg || !msg.payload || !msg.payload.success) {
    return
  }
  window.location.reload(true)
}

const activateAccount = data => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'ACTIVATE_ACCOUNT',
    payload: data
  })
  if (msg && msg.payload) {
    return msg.payload
  }
}

const sendPasswordToken = email => async (dispatch, getState, send) => {
  await send({
    type: 'SEND_PASSWORD_TOKEN',
    payload: {
      email
    }
  })
}

const resetPassword = (email, passwordToken, newPassword) => async (dispatch, getState, send) => {
  const msg = await send({
    type: 'RESET_PASSWORD',
    payload: {
      email,
      passwordToken,
      newPassword
    }
  })
  if (!msg || !msg.payload) {
    return {
      err: 'INVALID_PASSWORD_TOKEN'
    }
  }
  if (msg.payload.err) {
    return {
      err: msg.payload.err
    }
  }
  if (msg.payload.success) {
    return {
      success: true
    }
  }
}

export const actions = {
  validateSession,
  login,
  logout,
  activateAccount,
  sendPasswordToken,
  resetPassword
}

export const reducer = (state = initState, action) => {
  switch (action.type) {
  case 'AUTHENTICATE':
    return {
      ...state,
      authenticated: action.payload.authenticated,
      allowedActions: action.payload.allowedActions
    }
  case 'SESSION_CHECKED':
    return {
      ...state,
      sessionChecked: action.payload.sessionChecked
    }
  default:
    return state
  }
}