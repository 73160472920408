import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'


// import checkList from 'assets/icons/playlist-check.svg'
import chevronDown from '../assets/icons/chevron-down.svg'
// import chevronLeft from 'assets/icons/chevron-left.svg'
import chevronRight from '../assets/icons/chevron-right.svg'
import chevronUp from '../assets/icons/chevron-up.svg'
// import close from 'assets/icons/close.svg'
// import dotsVertical from 'assets/icons/dots-vertical.svg'
// import search from 'assets/icons/magnify.svg'
// import menu from 'assets/icons/menu.svg'
import lock from '../assets/icons/lock.svg'
import lockOpen from '../assets/icons/lock-open.svg'
import power from '../assets/icons/power.svg'
import dotsVertical from '../assets/icons/dots-vertical.svg'

const icons = {
  lock,
  lockOpen,
  // checkList,
  chevronDown,
  // chevronLeft,
  chevronRight,
  chevronUp,
  // close,
  dotsVertical,
  // menu,
  power
  // search,
}

const SVGIcon = ({ className, name, ...rest }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{__html: icons[name]}}
    {...rest}
  />
)

export default styled(SVGIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 56px;
  height: 56px;
  padding: 16px;

  ${p => p.small && `
    width: 32px;
    height: 32px;
    padding: 8px;
  `}

  ${p => p.large && `
    padding: 10px;
  `}

  ${p => p.nonclickable && `
    cursor: default;
  `}

  ${p => p.hover && css`
    transition: ease-out 350ms;
    
    &:hover {
      background: #${p.hover};
    }
  `}

  svg {
    width: 100%;

    path {
      fill: ${p => p.color ? p.color: '#e8e8e8'};
    }
  }
`
