import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../../store/app'

class Search extends Component {
  state = {
    searchQuery: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      this.setState(() => ({
        searchQuery: this.props.searchQuery
      }))
    }
  }

  handleFormSubmit = e => {
    const { actions, history } = this.props
    e.preventDefault()
    const query = e.target.elements.query.value
    actions.app.resetSearchMedia()
    actions.app.setSearchQuery(query)
    actions.app.search(query)
    history.push(`/search/search-query/${encodeURIComponent(btoa(query))}`)
  }

  updateSearchQuery = e => {
    const searchQuery = e.target.value
    this.setState(() => ({
      searchQuery
    }))
  }

  render() {
    const { className } = this.props
    const { searchQuery } = this.state
    return (
      <div className={className}>
        <form onSubmit={this.handleFormSubmit}>
          <input
            type='text'
            name='query'
            value={searchQuery}
            onChange={this.updateSearchQuery}
            placeholder='Nach Medien suchen'
            autoFocus
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchQuery: state.app.searchQuery
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Search)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 400px;
    height: 32px !important;
    padding: 0 8px;
    border: 0;
    font-weight: 300;
    color: #555;
    border-bottom: 2px solid ${p => p.theme.colors.main};
  }
`))
