import React from 'react'
import {Route} from 'react-router-dom'
import styled from 'styled-components'

import MediaList from '../../components/MediaList'
import Details from '../Details'
import SortBy from './SortBy'

const CategoryMedia = ({
  className,
  items,
  match,
  showLoadMore,
  onLoadMore,
  loading,
  onSortBy
}) => {
  return (
    <div className={className}>
      <SortBy
        onSortBy={onSortBy}
      />
      <MediaList
        loading={loading}
        items={items}
        match={match}
        showLoadMore={showLoadMore}
        onLoadMore={onLoadMore}
      />
      <Route
        path={`${match.path}/media/media-id/:mediaID`}
        component={Details}
      />
    </div>
  )
}

export default styled(CategoryMedia)``
