import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, CheckBox, Input, Note } from 'react-ui'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import Error from './Error'
import { Content } from './Login'
import SecondaryButtons from './SecondaryButtons'

import { actions as authActions } from './store'
import Validator from './Validator'

const AcceptPrivacy = styled(CheckBox)`
  margin-top: 32px;
  margin-bottom: 16px;

  span {
    a {
      display: inline;
      color: ${p => p.theme.colors.main};

      &:hover {
        color: #888;
      }
    }
  }

`

class ActivateAccount extends Component {
  state = {
    err: '',
    privacyAccepted: false,
    email: '',
    accountActivationToken: '',
    firstName: '',
    lastName: '',
    password: ''
  }

  activateAccount = e => {
    e.preventDefault()
    const { actions } = this.props
    const attrs = ['email', 'accountActivationToken', 'firstName', 'lastName', 'password']
    const accountData = {}
    for (const attr of attrs) {
      accountData[attr] = e.target.elements[attr].value.trim()
    }
    this.setState(() => ({
      err: ''
    }), async () => {
      const accountDataErr = Validator.validateAccountData(accountData)
      if (accountDataErr) {
        this.setState(() => ({
          err: accountDataErr
        }))
        return
      }
      const res = await actions.auth.activateAccount(accountData)
      if (res && res.err) {
        this.setState(() => ({
          err: res.err
        }))
      } else if (res && res.success) {
        await actions.auth.login(accountData.email, accountData.password)
      }
    })
  }

  update = e => {
    const { name, value } = e.target
    this.setState(() => ({
      [name]: value
    }))
  }

  errText = err => {
    switch (err) {
      case 'INVALID_ACCOUNT_ACTIVATION_TOKEN':
        return 'Ungültiger Account-Aktivierungs-Token.'
      default:
        return err
    }
  }

  updatePrivacyAccepted = e => {
    const accepted = e.target.checked
    this.setState(() => ({
      privacyAccepted: accepted
    }))
  }

  isValidForm = () => {
    const {
      email,
      accountActivationToken,
      firstName,
      lastName,
      password,
      privacyAccepted
    } = this.state
    return email
      && accountActivationToken
      && firstName
      && lastName
      && password
      && privacyAccepted
  }

  render() {
    const { className, onShowLogin } = this.props
    const { err } = this.state
    return (
      <div className={className}>
        <Content>
          <h1>Account aktivieren</h1>
          <Note>Bitte füllen Sie alle nachfolgenden Felder aus.</Note>
          <form onSubmit={this.activateAccount}>
            <Input
              label="E-Mail"
              name="email"
              onChange={this.update}
              autoFocus
            />
            <Input
              label="Account-Aktivierungs-Token"
              name="accountActivationToken"
              onChange={this.update}
            />
            <Input
              label="Vorname"
              name="firstName"
              onChange={this.update}
            />
            <Input
              label="Nachname"
              name="lastName"
              onChange={this.update}
            />
            <Input
              type="password"
              label="Passwort"
              name="password"
              onChange={this.update}
              placeholder="Min. 8 Zeichen"
            />
            <AcceptPrivacy
              onChange={this.updatePrivacyAccepted}
            >
              Ich stimme den <a href="/privacy" target="privacy">Datenschutzbestimmungen</a> zu.
            </AcceptPrivacy>
            {err && (
              <Error>{this.errText(err)}</Error>
            )}
            <Button
              disabled={!this.isValidForm()}
            >
              Account aktivieren
            </Button>
            <SecondaryButtons>
              <Button
                type="button"
                secondary="true"
                onClick={onShowLogin}
              >
                Zurück zum Login
              </Button>
            </SecondaryButtons>
          </form>
        </Content>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(ActivateAccount)`
  width: 640px;
`)
