import React from 'react'
import styled from 'styled-components'
import BlockHeader from './BlockHeader';

const Item = styled.li`
  display: flex;
`

const ItemCount = styled.div`
  text-align: right;
  width: 24px;
  font-weight: bold;
`

const ItemSeperator = styled.div`
  margin: 0 8px;
`

const Components = ({ className, items = [] }) => (
  <div className={className}>
    <BlockHeader>Bestandteile</BlockHeader>
    {items.length > 0 ? (
      <ul>
        {items.map((item, idx) => (
          <Item key={idx}>
            <ItemCount>{item.count}</ItemCount>
            <ItemSeperator>x</ItemSeperator>
            {item.name}
          </Item>
        ))}
      </ul>
    ) : (
      <div>-</div>
    )}
  </div>
)

export default styled(Components)`
  margin-top: 32px;
`
