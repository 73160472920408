import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import cc from 'classcat'

const items = [{
  text: 'Kontakt',
  to: '/contact'
}, {
  text: 'Datenschutz',
  to: '/privacy'
}]

class Footer extends Component {
  state = {
    show: false
  }
  componentDidMount() {
    this.setState(() => ({
      show: true
    }))
  }
  render() {
    const { className } = this.props
    const { show } = this.state
    return (
      <div className={cc({
        [className]: true,
        show
      })}>
        <nav>
          <ul>
            {items.map(item => (
              <li
                key={item.text}
              >
                <NavLink
                  to={item.to}
                >
                  {item.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    )
  }
}

export default styled(Footer)`
  padding: 16px;
  color: #333;
  font-size: 12px;
  text-align: right;
  flex-shrink: 0;
  background: #555;
  opacity: 0;
  transition: opacity ease-out 500ms 200ms;
  
  &.show {
    opacity: 1;
  }

  ul {
    display: flex;
    justify-content: center;
  }

  li:not(:last-of-type) {

    &::after {
      content: "|";
      margin: 0 8px;
      color: #fff;
    }
  }

  a {
    color: #fff;
    line-height: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
`
