import React from 'react'
import styled from 'styled-components'

import ScrollMainContentToTopOnMount from './ScrollMainContentToTopOnMount'

const Content = styled.div`
  max-width: 1200px;
`

const Contact = ({ className }) => (
  <div className={className}>
    <Content>
      <ScrollMainContentToTopOnMount />
      <h1>Impressum</h1>
      <p>Bei Anfragen, Beschwerden, Hinweisen und Anregungen wenden Sie sich bitte zunächst an die
        Verantwortlichen für die Website: Sandra Hau.</p>
      <p><em>Verantwortliche für Gestaltung und Redaktion:</em><br />
        Sandra Hau
      </p>
      <p>Gemeinschaftsschule Neunkirchen<br />
        Haspelstraße<br />
        66538 Neunkirchen</p>
      <p>Tel.: 06821 98 150<br />
        Fax: 06821 98 15 35<br />
        E-Mail: info@ggsnk.de
      </p>
      <p>
        <br/>
        Diensteanbieter dieses Internetangebots ist<br /><br />
        Konstantin Bernhardt Software Engineering<br />
        Finkenweg 1<br />
        66538 Neunkirchen<br /><br />
        Tel.: 06821/3099264<br />
        E-mail: info@librishare.de
      </p>
    </Content>
  </div>
)

export default styled(Contact)`
  padding: 32px;
  flex: 1;
  background: ${p => p.theme.colors.background};

  h1 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 0;
  }

  a {
    color: ${p => p.theme.colors.main};
  }

  ul {
    list-style: initial !important;
    margin-left: 24px;
  }

  p {
    margin-bottom: 16px;
  }
`
