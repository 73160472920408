import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../../store/app'
import Login from './Login'
import ResetPassword from './ResetPassword';
import ActivateAccount from './ActivateAccount';

class Auth extends Component {
  state = {
    showView: 'login'
  }
  showView = name => e => {
    this.setState(() => ({
      err: '',
      showView: name
    }))
  }
  render() {
    const { className } = this.props
    const { showView } = this.state
    return (
      <div className={className}>
        {showView === 'login' &&
          <Login 
            onShowResetPassword={this.showView('resetPassword')}
            onShowActivateAccount={this.showView('activateAccount')}
          />
        }
        {showView === 'resetPassword' && (
          <ResetPassword
            onShowLogin={this.showView('login')}
          />
        )}
        {showView === 'activateAccount' && (
          <ActivateAccount
            onShowLogin={this.showView('login')}
          />
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(Auth)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px auto;
  margin-bottom: 16px;

  h1 {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  button {
    width: 100%;
  }

  a {
    display: block;
    line-height: 32px;
    color: #888;
    transition: ease-out 350ms;
    text-align: center;

    &:hover {
      color: #333;
    }
  }
`)
