import { createGlobalStyle } from 'styled-components'
import RobotoLight from '../assets/fonts/roboto-v18-latin-300.woff2'
import RobotoRegular from '../assets/fonts/roboto-v18-latin-regular.woff2'
import RobotoBold from '../assets/fonts/roboto-v18-latin-700.woff2'

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`
  /* roboto-300 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
        url(${RobotoLight}) format('woff2');
  }

  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
        url(${RobotoRegular}) format('woff2');
  }

  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url(${RobotoBold}) format('woff2');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  body {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    background: #fff;
  }
`

export default GlobalStyle
