import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import SVGIcon from './SVGIcon'

const Inline = styled.div`
  display: flex;
`

const CategoryPath = ({ className, categories, disableLastLink, onSelect }) => {
  return (
    <div className={className}>
      <ul>
        {categories.map((category, idx) => (
          <li
            key={category.id}
          >
            {disableLastLink && idx == categories.length - 1 ? (
              <div>{category.name}</div>
            ) : (
              <NavLink
                to={`/special-fields/${category.specialFieldID}/category-id/${category.id}`}
              >
                {category.name}
              </NavLink>
            )}
            {idx !== categories.length - 1 && (
              <SVGIcon
                name='chevronRight'
                color='#888'
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

CategoryPath.defaultProps = {
  onSelect: () => {}
}

export default styled(CategoryPath)`

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 32px;
  }

  li {
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  ${SVGIcon} {
    width: 32px;
    height: 16px;
    padding: 8px;
    cursor: default;
  }

  a {
    color: ${p => p.theme.colors.main};
    transition: ease-out 350ms;

    &:hover {
      color: #333;
      cursor: pointer;
    }

    &.selected {
      color: #333;
    }
  }
`