import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { Menu } from 'react-ui'

import { actions as authActions } from '../Auth/store'

class MemberProfile extends Component {
  state = {
    items: []
  }

  componentDidMount() {
    const { allowedActions } = this.props
    const items = []
    if (allowedActions && allowedActions.length) {
      items.push({
        text: 'Medienkatalog verwalten',
        action: 'MANAGE_CATALOG'
      })
    }
    items.push({
      text: 'Ausloggen',
      action: 'LOGOUT'
    })
    this.setState(() => ({
      items
    }))
  }

  logout = () => {
    const { actions } = this.props
    actions.auth.logout()
  }

  handleSelect = action => {
    const actions = {
      MANAGE_CATALOG: () => {
        const target = process.env.NODE_ENV === 'production'
          ? 'https://lernzentrum.ggsnk.de/admin'
          : 'http://localhost:8081'
        window.location.href = target
      },
      LOGOUT: this.logout
    }
    if (actions[action]) {
      actions[action]()
    }
  }

  render() {
    const { className } = this.props
    const { items } = this.state
    return (
      <div className={className}>
        <Menu
          icon='account'
          items={items}
          onSelect={this.handleSelect}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  allowedActions: state.auth.allowedActions
})

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(MemberProfile)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
`)
