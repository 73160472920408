import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../../store/app'
import URLService from '../../services/url'
import CategoriesPaths from './CategoriesPaths'
import Components from './Components'
import BlockHeader from './BlockHeader'
import TeachingMaterials from './TeachingMaterials'

const Content = styled.div`
  max-width: 800px;
  color: #333;
`

const Title = styled.h1`
  word-break: break-all;
`

const Description = styled.div`
  margin-top: 16px;
  max-height: 320px;
  overflow: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  padding: 1px;
`

const Location = styled.div`
  margin-top: 32px;
`

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ImageLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  max-width: 160px;
  min-height: 160px;
  max-height: 160px;
  border: 1px solid #dfdfdf;
  margin-bottom: 8px;
  opacity: 1;
  transition: ease-out 350ms;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const Tags = styled.div`
  display: flex;
`

const Tag = styled.div`
  &:not(:last-of-type) ::after {
    content: ',\00a0'
  }
`

class Details extends Component {
  state = {}

  componentDidMount() {
    const { actions, match } = this.props
    const { mediaID } = match.params
    actions.app.mediaByID(mediaID)
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    const { actions } = this.props
    actions.app.resetMedia()
    document.body.style.overflow = 'auto'
  }

  handleCategorySelect = category => {
    const { history } = this.props
    history.push(`/special-fields/${category.specialFieldID}/category-id/${category.id}`)
  }
  
  renderImages() {
    const { media } = this.props
    const { images = [] } = media
    return (
      <div>
        <BlockHeader>Bilder</BlockHeader>
        <Images>
          {images.length === 0 && (
            <div>-</div>
          )}
          {images.map(image => (
            <ImageLink
              key={image.id}
              href={`${URLService.url}/static/media/${media.id.substr(0, 2)}/${media.id}/images/${image.src}`}
              target='_blank'
            >
              <img
                src={`${URLService.url}/static/media/${media.id.substr(0, 2)}/${media.id}/images/th_${image.src}`}
              />
            </ImageLink>
          ))}
        </Images>
      </div>
    )
  }
  renderTags() {
    const { media: { tags = [] } } = this.props
    return (
      <div>
        <BlockHeader>Tags</BlockHeader>
        <Tags>
          {tags.map(tag => (
            <Tag key={tag}>
              {tag}
            </Tag>
          ))}
        </Tags>
      </div>
    )
  }

  renderCategories() {
    const { media } = this.props
    return (
      <div>
        <BlockHeader>Kategorien</BlockHeader>
        <CategoriesPaths
          categories={media.categories}
          onSelect={this.handleCategorySelect}
        />
      </div>
    )
  }

  render() {
    const { className, media } = this.props
    if (!media.id) {
      return null
    }
    return (
      <div className={className}>
        <Content>
          <Title>{media.title}</Title>
          <Description>
            {media.description}
          </Description>
          <Location>
            <strong>Standort:</strong> {media.location || '-'}
          </Location>
          <Components items={media.components} />
          { this.renderImages() }
          <TeachingMaterials media={media} />
          { this.renderTags() }
          { this.renderCategories() }
        </Content>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  media: state.app.media
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(Details)`
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding: 32px;
  font-weight: 300;

  a {
    color: ${p => p.theme.colors.main};
    transition: ease-out 350ms;

    &:hover {
      color: #333;
    }
  }

  h1 {
    margin: 0;
    font-weight: 300;
    font-size: 24px;
  }
`)
