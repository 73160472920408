import 'normalize.css'
import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'

import configureStore from './store/configureStore'
import GlobalStyle from './styles/main'
import Views from './views'
import theme from './styles/theme'

const store = configureStore()

class App extends Component {
  componentDidCatch(err, info) {
    console.error(err, info)
  }

  render() {
    const { className } = this.props
    return (
      <div className={className}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle />
              <BrowserRouter>
                <Route path="/" component={Views} />
              </BrowserRouter>
            </>
          </ThemeProvider>
        </Provider>
      </div>
    )
  }
}

export default styled(App)`
  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  input {
    height: 48px;
  }
`
