import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { handleMenuSelect } from './Header/Navigation'

const Box = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => p.color || p.theme.colors.main};
  width: 240px;
  height: 160px;
  margin: 16px;
  opacity: 0.9;
  ${p => p.theme.transition};
`

const Text = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  color: ${p => p.color || '#777'};
  background: #fff;
  border-radius: 50%;
  padding: 16px;
  ${p => p.theme.transition};
`

const BoxWrapper = styled.div`
  cursor: pointer;
  
  ${Box} {
    border-radius: ${p => p.reverse ? '80% 50%' : '50% 80%'};
  }
  
  ${Text} {
    border-radius: ${p => !p.reverse ? '60% 50%' : '50% 60%'};
  }
    
  :hover {
    ${Box} {
      opacity: 1;
      border-radius: ${p => !p.reverse ? '80% 50%' : '50% 80%'};
      box-shadow: 0 0 32px #ffffffdd;
    }  
    
    ${Text} {
      border-radius: ${p => p.reverse ? '60% 50%' : '50% 60%'};
    }
  }
`

const Boxes = styled.div`
  display: flex;
  justify-content: center;  
  flex-wrap: wrap;
  width: 1088px;
`

const SpecialFields = ({ className, specialFields = [], history }) => {
  const handleSelect = specialFieldId => () => {
    handleMenuSelect(specialFields, history)(specialFieldId)
  }

  return (
    <div className={className}>
      <Boxes>
        {specialFields.map((speciaField, idx) => (
          <BoxWrapper
            key={speciaField.id}
            reverse={idx % 2 !== 0}
            onClick={handleSelect(speciaField.id)}
          >
            <Box
              color={speciaField.color}
            >
              <Text
                color={speciaField.color}
              >
                {speciaField.name}
              </Text>
            </Box>
          </BoxWrapper>
        ))}
      </Boxes>
    </div>
  )
}

const mapStateToProps = state => ({
  specialFields: state.app.specialFields
})

export default connect(
  mapStateToProps
)(styled(SpecialFields)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`)