import React from 'react'
import styled from 'styled-components'
import ScrollMainContentToTopOnMount from './ScrollMainContentToTopOnMount'

const Content = styled.div`
  max-width: 1200px;
`

const Privacy = ({ className }) => (
  <div className={className}>
    <Content>
      <ScrollMainContentToTopOnMount />
      <h1>Datenschutzerklärung</h1>
      <p><strong>&nbsp;</strong><u>Verantwortlicher und Datenschutzbeauftragter</u></p>
      <p>1.1 Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (EU-DSGVO) ist die
        Ganztagsgemeinschaftschule Neunkirchen, Haspelstr., 66538 Neunkirchen <a
          href="mailto:info@ggsnk.de">info@ggsnk.de</a> (siehe unser Impressum).</p>
      <p>1.2 Unsere Datenschutzbeauftragte erreichen Sie unter
        datenschutzbeauftragte@bildung.saarland.de oder der Adresse des Ministeriums für Bildung und
        Kultur, Trierer Straße 33, 66111 Saarbrücken mit dem Zusatz „Die
        Datenschutzbeauftragte“.</p>
      <p>&nbsp;</p>
      <ol start="2">
        <li><u> Information über die Erhebung personenbezogener Daten, Speicherdauer</u></li>
      </ol>
      <p>2.1 Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung
        unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar
        sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p>
      <p>2.2 Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die
        von
        Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
        gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten
        löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die
        Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
      <p>2.3 Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister
        zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie
        untenstehend
        im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten
        Kriterien der Speicher-dauer.</p>
      <p>&nbsp;</p>
      <ol start="3">
        <li><u> Ihre Rechte</u></li>
      </ol>
      <p>3.1 Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden
        personenbezogenen Daten:</p>
      <ul>
        <li>Recht auf Auskunft,</li>
        <li>Recht auf Berichtigung oder Löschung,</li>
        <li>Recht auf Einschränkung der Verarbeitung,</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
        <li>Recht auf Datenübertragbarkeit.</li>
      </ul>
      <p>3.2 Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die
        Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren. Die
        Datenschutzaufsichtsbehörde ist die Landesbeauftragte für den Datenschutz und die
        Informationsfreiheit (LfDI), Fritz-Dobisch-Str. 12, 66111 Saarbrücken.</p>
      <p>3.4 Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation
        übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäß Art. 46 EU-DSGVO im
        Zusammenhang mit der Übermittlung unterrichtet zu werden.</p>
      <p>&nbsp;</p>
      <ol start="4">
        <li><u> Erhebung und Zweck personenbezogener Daten bei Besuch unserer Website</u></li>
      </ol>
      <p>4.1 Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht
        registrieren
        oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten,
        die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten,
        erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere
        Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist
        Art. 6 Abs. 1 S. 1 lit. f EU-DSGVO):</p>
      <ul>
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
      <p>4.2 Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website
        Cookies
        auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf
        Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch
        welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen.
        Cookies können keine Pro-gramme ausführen oder Viren auf Ihren Computer übertragen. Sie
        dienen
        dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.</p>
      <p>4.3 Einsatz von Cookies <em>(Diesen Absatz können Sie entfernen, wenn Sie keine Cookies
        verwenden bzw. müssen ihn in Anhängigkeit von den eingesetzten Cookies anpassen)</em></p>
      <ol>
        <li>a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im
          Folgenden erläutert werden:
        </li>
      </ol>
      <ul>
        <li>Transiente Cookies (dazu b)</li>
        <li>Persistente Cookies (dazu c).</li>
      </ul>
      <ol>
        <li>b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen.
          Dazu
          zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit
          welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen.
          Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren.
          Die
          Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
        </li>
        <li>c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die
          sich je nach Cookie unterscheiden kann. Sie können die Cookies in den
          Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
        </li>
        <li>d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z.
          B.
          die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf
          hin,
          dass Sie eventuell nicht alle Funktionen dieser Website nutzen können.
        </li>
        <li>e) [Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie
          über einen Account bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut
          einloggen.]
        </li>
        <li>f) [Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch
          Ihr Flash-Plug-in. Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät
          abgelegt werden. Diese Objekte speichern die erforderlichen Daten unabhängig von Ihrem
          verwendeten Browser und haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung
          der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On installieren, z. B.
          „Better
          Privacy“ für Mozilla Firefox (https://addons.mozilla.org/de/firefox/addon/betterprivacy/)
          oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von HTML5 storage
          objects
          können Sie verhindern, indem Sie in Ihrem Browser den privaten Modus einsetzen. Zudem
          empfehlen wir, regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu löschen.]
        </li>
      </ol>
      <p>&nbsp;</p>
    </Content>
  </div>
)

export default styled(Privacy)`
  padding: 32px;
  background: ${p => p.theme.colors.background};
  flex: 1;

  h1 {
    font-size: 24px;
    font-weight: 300;
    margin-top: 0;
  }

  a {
    color: ${p => p.theme.colors.main};
  }

  ul {
    list-style: initial !important;
    margin-left: 24px;
  }

  p {
    margin-bottom: 16px;
  }
`
