import styled from 'styled-components'

const SecondaryButtons = styled.div`
  margin-top: 16px;

  button {
    color: #888;
    margin: 0;
    height: 32px;
    line-height: 32px;
  }
`

export default SecondaryButtons