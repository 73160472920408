import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import Menu from 'react-ui/build/Menu'
import styled from 'styled-components'

const url = specialField => {
  return `/special-fields/${specialField.id}/category-id/${specialField.rootCategory.id}`
}

const navMenuItems = specialFields => {
  return specialFields.map(specialField => (
    {
      text: specialField.name,
      action: specialField.id
    }
  ))
}

export const handleMenuSelect = (specialFields, history) => action => {
  const actionSpecialField = specialFields.find(specialField => specialField.id === action)
  history.push(url(actionSpecialField))
}

const NavItems = styled.ul`
  display: flex;
  height: 100%;
`

const NavMenu = styled(Menu)`
  display: block;

  @media (min-width: 1600px) {
    display: none;
  }
`

const StyledNavLink = styled(NavLink)`
  color: #555;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  transition: ease-out 350ms;
  border-bottom: 4px solid transparent;

  &:hover {
    border-bottom-color: #dfdfdf;
  }

  &.active {
    border-bottom-color: ${p => p.color || p.theme.colors.main};
  }
`

const Navigation = ({ className, specialFields, history }) => {
  if (!specialFields.length) {
    return null
  }
  return (
    <nav className={className}>
      <NavItems>
        {specialFields.map(specialField => (
          <li key={specialField.id}>
            <StyledNavLink
              to={url(specialField)}
              color={specialField.color}
            >
              {specialField.name}
            </StyledNavLink>
          </li>
        ))}
      </NavItems>
      <NavMenu
        icon="menu"
        items={navMenuItems(specialFields)}
        onSelect={handleMenuSelect(specialFields, history)}
      />
    </nav>
  )
}

export default withRouter(styled(Navigation)`
  display: flex;
  align-items: center;

  ${NavItems} {
    display: none;
  }

  @media (min-width: 1600px) {
    ${NavItems} {
      display: flex;
      margin-right: 16px;
    }
  } 
`)