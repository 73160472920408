import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Input, Button } from 'react-ui'

import { actions as authActions } from './store'
import Validator from './Validator'
import SecondaryButtons from './SecondaryButtons'
import Error from './Error'

export const Content = styled.div`
  background: ${p => p.theme.colors.background};
  padding: 16px 32px;
  box-shadow: 0 0 32px #00000055;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
`

const LoginButton = styled(Button)`
  &:disabled {
    opacity: 0.5;
  }
`

class Login extends Component {
  state = {
    err: '',
    email: '',
    password: ''
  }

  update = e => {
    const { name, value } = e.target
    this.setState(() => ({
      [name]: value,
      err: ''
    }))
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const { actions } = this.props
    let { email, password } = this.state
    email = email.trim()
    password = password.trim()
    this.setState(() => ({
      err: ''
    }), async () => {
      const emailErr = Validator.validateEmail(email)
      if (emailErr) {
        this.setState(() => ({
          err: emailErr
        }))
        return
      }
      const passwordErr = Validator.validatePassword(password)
      if (passwordErr) {
        this.setState(() => ({
          err: passwordErr
        }))
        return
      }
      const res = await actions.auth.login(email, password)
      if (res && res.err) {
        this.setState(() => ({
          err: res.err
        }))
      }
    })
  }

  errText = err => {
    switch (err) {
    case 'INVALID_EMAIL':
      return 'Ungültige E-Mail Adresse'
    case 'ACCOUNT_LOCKED':
      return 'Dieser Account ist gesperrt'
    case 'INVALID_CREDENTIALS':
      return 'E-Mail und Passwort stimmen nicht überein'
    default:
      return err
    }
  }

  render() {
    const { className, onShowResetPassword, onShowActivateAccount } = this.props
    const { err, email, password } = this.state
    return (
      <div className={className}>
        <Content>
        <h1>Login</h1>
        <form onSubmit={this.handleFormSubmit}>
          <Input
            label='E-Mail'
            name='email'
            value={email}
            autoFocus
            onChange={this.update}
          />
          <Input
            type='password'
            label='Passwort'
            name='password'
            value={password}
            onChange={this.update}
          />
          {err && (
            <Error>{this.errText(err)}</Error>
          )}
          <LoginButton
            disabled={!email || !password}
          >
            Anmelden
          </LoginButton>
          <SecondaryButtons>
            <Button
              type='button'
              secondary='true'
              onClick={onShowResetPassword}
            >
              Passwort vergessen?
            </Button>
            <Button
              type='button'
              secondary='true'
              onClick={onShowActivateAccount}
            >
              Account aktivieren
            </Button>
          </SecondaryButtons>
        </form>
        </Content>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(authActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(Login)`
  width: 480px;
`)
