import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../store/app'
import Search from './Search'
import SpecialField from './SpecialField'
import SpecialFields from './SpecialFields'


const MainView = ({ className, actions }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    init()
    .then()
  }, [])

  const init = async () => {
    await actions.app.getSpecialFields()
    setLoaded(true)
  }

  const renderContent = () => (
    <Switch>
      <Route
        path="/special-fields/:specialField"
        component={SpecialField}
      />
      <Route
        path="/search/search-query/:searchQuery"
        component={Search}
      />
      <Route
        path="/"
        exact
        component={SpecialFields}
      />
    </Switch>
  )


  return (
    <div className={className}>
      {loaded && (
        renderContent()
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  specialFields: state.app.specialFields
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(MainView)`
  flex: 1;
  display: flex;
  flex-direction: column;
`))
