import URLService from './url'

const url = URLService.url + '/api'

const send = async msg => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(msg)
    })
    return res.json()
  } catch (err) {
    console.error(err)
    return {
      payload: {
        err: 'Verbindung zum Server fehlgeschlagen.'
      }
    }
  }
}

export default send