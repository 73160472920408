import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../../store/app'
import URLService from '../../services/url'
import FileUpload from '../../components/FileUpload'
import BlockHeader from './BlockHeader'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 8px;

  ${BlockHeader} {
    margin: 0;
  }
`

const Error = styled.div`
  padding: 0 16px;
  line-height: 32px;
  color: #fff;
  background: ${p => p.theme.colors.err};
`

class TeachingMaterials extends Component {
  state = {
    err: ''
  }

  handleFileSelect = teachingMaterials => {
    const { actions, media } = this.props
    this.setState(() => ({
      err: ''
    }), async () => {
      const success = await actions.app.addTeachingMaterials(media.id, teachingMaterials)
      if (!success) {
        this.setState(() => ({
          err: 'Unterrichtsmaterial konnte nicht hinzugefügt werden'
        }))
        return
      }
      actions.app.mediaByID(media.id)
    })
  }

  render() {
    const { className, media } = this.props
    const { err } = this.state
    const { teachingMaterials = [] } = media
    return (
      <div className={className}>
        <Header>
          <BlockHeader>Unterrichtsmaterialien</BlockHeader>
          <FileUpload
            icon='plus'
            title='Hinzufügen'
            onSelect={this.handleFileSelect}
          />
          {err && <Error>{err}</Error>}
        </Header>
        <ul>
          {teachingMaterials.map(teachingMaterial => {
            const url = `${URLService.url}/static/media/${media.id.substr(0, 2)}/${media.id}/teaching-materials/${teachingMaterial.id}/${teachingMaterial.name}`
            return (
              <li key={teachingMaterial.id}>
                <a
                  href={url}
                  target="_blank"
                >
                  {teachingMaterial.name}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  null,
  mapDispatchToProps
)(styled(TeachingMaterials)`
  margin-top: 16px;

  li {
    line-height: 32px;
  }

  ${FileUpload} {
    margin: 0 8px;
  }
`)
