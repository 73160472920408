import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'

import logoSmall from '../../assets/logo-small.png'

import Search from './Search'
import Navigation from './Navigation'
import MemberProfile from './MemberProfile'

const Inline = styled.div`
  display: flex;
  flex: 1;
`

const Logo = styled(Link)`
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 8px;
`

const LogoImage = styled.div`
  height: 46px;
  padding: 4px;

  img {
    height: 100%;
    margin-right: 8px;
  }
`

const LogoText = styled.div`
  font-size: 14px;
  color: #555;

  @media (max-width: 800px) {
    display: none;
  } 
`

const Header = ({ className, specialFields, authenticated }) => (
  <div className={className}>
    <Logo to="/">
      <LogoImage>
        <img src={logoSmall} />
      </LogoImage>
      <LogoText>
        <strong>GGSNK</strong> Ganztagsgemeinschaftsschule Neunkirchen<br />
        Lernzentrum - Medienkatalog
      </LogoText>
    </Logo>
    {authenticated && (
      <Inline>
        <Search />
        <Navigation
          specialFields={specialFields}
        />
        <MemberProfile />
      </Inline>
    )}
  </div>
)

const mapStateToProps = state => ({
  specialFields: state.app.specialFields,
  authenticated: state.auth.authenticated
})

export default withRouter(connect(
  mapStateToProps
)(styled(Header)`
  display: flex;
  flex-shrink: 0;
  height: ${p => p.theme.vars.headerHeight};
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
`))
