import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { actions as appActions } from '../../store/app'
import Categories from './Categories'

const SpecialField = ({ className, match }) => (
  <div className={className}>
    <Route
      path={`${match.path}/category-id/:categoryId`}
      component={Categories}
    />
  </div>
)

const mapStateToProps = state => ({
  selectedCategory: state.app.selectedCategory
})

const mapDispatchToProps = dispatch => ({
  actions: {
    app: bindActionCreators(appActions, dispatch)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(styled(SpecialField)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffffee;
`)
