import React from 'react'
import styled from 'styled-components'
import { ButtonStyle, SVGIcon } from 'react-ui'

const MAX_FILE_SIZE = 10 * 1024 * 1024

const AddFilesButton = styled.div`
  ${ButtonStyle}
  display: inline-block;
  margin: 0;
`

const AddIcon = styled(SVGIcon)`
  height: 32px;
  width: 48px;
  padding: 12px;
  ${p => p.theme.transition};

  &:hover {
    background: #f7f8f2;
  }

  svg path {
    fill: ${p => p.theme.colors.main};
  }
`

const loadFile = async file => (
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = e => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(file)
  })
)

const handleFileSelect = onSelect => async e => {
  const { target } = e
  const { files } = target
  const newItems = []
  for (const [idx, file] of Array.from(files).entries()) {
    const newItem = {
      tmpID: Date.now() + idx,
      name: file.name
    }
    if (file.size <= MAX_FILE_SIZE) {
      newItem.data = await loadFile(file)
    } else {
      newItem.err = 'MAX_FILE_SIZE'
    }
    newItems.push(newItem)
  }
  target.value = null
  onSelect(newItems)
}

const FileUpload = ({ className, buttonText, icon, title, onSelect }) => (
  <div className={className}>
    <label>
      <input
        type='file'
        multiple
        onChange={handleFileSelect(onSelect)}
      />
      {buttonText && (
        <AddFilesButton>
          {buttonText}
        </AddFilesButton>
      )}
      {icon && (
        <AddIcon
          name={icon}
          title={title}
        />
      )}
    </label>
  </div>
)

export default styled(FileUpload)`
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`
