import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-ui'
import styled from 'styled-components'

import logoSmall from '../assets/logo-small.png'
import URLService from '../services/url'

const Items = styled.ul``

const Item = styled.li`
  border: 1px solid #dfdfdf;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 0 8px #00000011;
  border-radius: 4px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

const Inline = styled.div`
  display: flex;
`

const StyledLink = styled(Link)`
  h1 {
    overflow-wrap: break-word;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 8px 16px;
  max-height: 160px;
  overflow: hidden;
`

const NoCoverImg = styled.img`
  transition: ease-out 350ms;
`

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  background: #fafafa;
  transition: ease-out 350ms;

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const Note = styled.div`
  color: #888;
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
`

const Description = styled.div`
  position: relative;
  white-space: pre-wrap;
  color: #555;
  height: 118px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 48px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
  }
`

const LoadMoreButton = styled(Button)`
  display: flex;
  margin: 32px auto;
`

const renderCover = (media, mediaDetailsURL) => (
  <Link to={mediaDetailsURL}>
    <Cover>
      {media.cover && media.cover.length > 0 ? (
        <img
          src={`${URLService.url}/static/media/${media.id.substr(0, 2)}/${media.id}/images/th_${media.cover}`}
        />
      ) : (
        <NoCoverImg
          src={logoSmall}
        />
      )}
    </Cover>
  </Link>
)

const MediaList = ({ className, items, match, showLoadMore, onLoadMore, loading }) => {
  if (loading) {
    return (
      <div className={className}>
        {/*<Loader />*/}
      </div>
    )
  }
  if (!loading && !items.length) {
    return (
      <div className={className}>
        <Note>Keine passenden Medien gefunden.</Note>
      </div>
    )
  }
  return (
    <div className={className}>
      <Items>
        {items.map(media => {
          const mediaDetailsURL = `${match.url}/media/media-id/${media.id}`
          return (
            <Item
              key={media.id}
            >
              <Inline>
                {renderCover(media, mediaDetailsURL)}
                <Content>
                  <StyledLink to={mediaDetailsURL}>
                    <h1>{media.title}</h1>
                  </StyledLink>
                  <Description>
                    {media.description}
                  </Description>
                </Content>
              </Inline>
            </Item>
          )
        })}
      </Items>
      {showLoadMore && (
        <LoadMoreButton
          onClick={onLoadMore}
        >
          Mehr laden
        </LoadMoreButton>
      )}
    </div>
  )
}

export default styled(MediaList)`
  max-width: 800px;

  h1 {
    margin: 0;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    color: ${p => p.theme.colors.main};
    transition: ease-out 350ms;

    &:hover {
      color: #333;
    }
  }
`
